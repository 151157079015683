import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import ShareSocial from "../components/share"
import {Container} from 'react-bootstrap'
import AdvertContact from "../components/advert"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Certs from "../components/certs"

export default function BioTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  let featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid
  return (
    <>
      <Header />
      <SEO title={frontmatter.name} />
      <Container className="mt-2">
        <div className="blog-post-container" style={{display:'flex', justifyContent: 'center'}}>
          <div className="blog-post mt-4" style={{maxWidth: 900}}>
            <div style={{display: "flex", justifyContent: "center"}}>
              <Img fluid={featuredImgFluid} className="mb-4 mt-4" 
                style={{height: 140, width: 140, borderRadius: 100}}
              />
            </div>
            <h1 style={{textAlign: "center"}} className="mb-4">{frontmatter.name}</h1>
            <h4 style={{textAlign: "center", color:"grey", fontFamily: "Helvetica"}} className="mb-5">{frontmatter.title}</h4>
            <div style={{justifyContent: 'center', display: 'flex'}} className="mb-5">
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center",
                borderRadius:0, backgroundColor: "#ff914d", height:12, width:12
              }}>
                {/* <div className="ops-icon" id="imgOpsDataIcon"></div> */}
              </div>
              <div style={{minWidth: 30}}></div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center",
                borderRadius:"100%",backgroundColor: "grey", height:12, width:12
              }}>
                {/* <div className="ops-icon" id="imgOpsCloudIcon"></div> */}
              </div>
              <div style={{minWidth: 30}}></div>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: "center",
                borderRadius:0,backgroundColor: "lightgrey", height:12, width:12
              }}>
                {/* <div className="ops-icon" id="imgOpsAppIcon"></div> */}
              </div>
            </div>
            <div
              className="blog-post-content mt-3 mb-2"
              dangerouslySetInnerHTML={{ __html: html }}
              align='justify'
            />
            <div className="mb-5">
              <Certs certsList={frontmatter.certsList?.split(" ")}/>
            </div>
          </div>
        </div>
        <ShareSocial post={markdownRemark}/>
        <AdvertContact />
      </Container>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        name
        certsList
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        
        # author
      }
    }
  }
`
