
import React from "react"
import { AiFillTwitterCircle, AiFillLinkedin } from 'react-icons/ai'

const ShareSocial = ({ post }) =>  (
    <div className="d-flex justify-content-center">
        <h2 className="share-link mr-2">Share</h2>
        <a 
            href={`https://twitter.com/intent/tweet/?text=${
                post.frontmatter.title
                }&url=https://opsmorph.com${post.frontmatter.slug}%2F&via=opsmorph`}
            target="_blank"
            rel="noreferrer"
        >
            <AiFillTwitterCircle style={{fontSize:'40px', color:'cyan'}} className="pd-4"/>
        </a>
        <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=https://opsmorph.com${
            post.frontmatter.slug
            }&title=${post.frontmatter.title}&source=${post.frontmatter.title}`}
            target="_blank"
            rel="noreferrer"
        >
            <AiFillLinkedin style={{fontSize:'40px', color:'cyan'}} className="pd-4"/>
        </a>
    </div>
)

export default ShareSocial