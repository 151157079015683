import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function Certs(props) {
  const { certsList } = props;
  const certsImageData = useStaticQuery(graphql`
    query {

      q1: file(relativePath: { eq: "aws-certified-solutions-architect-associate.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q2: file(relativePath: { eq: "aws-certified-security-specialty.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q3: file(relativePath: { eq: "awscert_practitioner.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      q4: file(relativePath: { eq: "aws-certified-devops-professional.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

    }
  `)
  
  return (
    <div style={{display: "flex", justifyContent: "center", flexDirection: "row", marginBottom: 4, marginTop: 4}}>
      {certsList.includes('solutions-architect') &&
        <Img
          style={{height: 125, width: 125}}
          fluid={certsImageData.q1.childImageSharp.fluid}
          alt={`AWS Solutions Architect`}
        />
      }
      {certsList.includes('security-specialty') &&
        <Img
          style={{height: 125, width: 125}}
          fluid={certsImageData.q2.childImageSharp.fluid}
          alt={`AWS Security Specialty`}
        />
      }
      {certsList.includes('practitioner') &&
        <Img
          style={{height: 125, width: 125}}
          fluid={certsImageData.q3.childImageSharp.fluid}
          alt={`AWS Practitioner`}
        />
      }
      {certsList.includes('devops-professional') &&
        <Img
          style={{height: 125, width: 125}}
          fluid={certsImageData.q4.childImageSharp.fluid}
          alt={`AWS Devops Professional`}
        />
      }
    </div>
  )
}